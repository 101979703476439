import { combineReducers } from "redux";
import authSlice from "./Slice/Auth/authSlice";
import dataCreationSlice from "./Slice/DataCreation/DataCreationSlice";
import indicatorSlice from "./Slice/Indicators/indicatorsSlice";
import notificationsSlice from "./Slice/Notifications/notificationsSlice";
import settingsSlice from "./Slice/Settings/settingsSlice";
import threatProfileSlice from "./Slice/ThreatProfile/threatProfileSlice";
import attackMatrixSlice from "./Slice/TID/AttackMatrixSlice";
import dashboardSlice from "./Slice/TID/DashboardSlice";
import detectionSlice from "./Slice/TID/DetectionSlice";
import entitySlice from "./Slice/TID/EntitySlice";
import myTasksSlice from "./Slice/TID/myTasksSlice";
import recommendationsSlice from "./Slice/TID/RecommendationsSlice";
import riskMatrixSlice from "./Slice/TID/RiskMatrixSlice";
import threatCoverageSlice from "./Slice/TID/ThreatCoverageSlice";
import ThreatScenarioSlice from "./Slice/TID/ThreatScenarioSlice";
import CampaignSlice from "./Slice/Campaign/CampaignSlice";
import WorkspaceSlice from "./Slice/TID/WorkspaceSlice";
import vocabularySlice from "./Slice/Vocabulary/vocabularySlice";

export const rootReducer = combineReducers({
  TIDEntity: entitySlice,
  attackMatrix: attackMatrixSlice,
  threatCoverage: threatCoverageSlice,
  detection: detectionSlice,
  recommendations: recommendationsSlice,
  myTasks: myTasksSlice,
  vocabulary: vocabularySlice,
  dataCreations: dataCreationSlice,
  riskMatrix: riskMatrixSlice,
  auth: authSlice,
  threatProfile: threatProfileSlice,
  workspace: WorkspaceSlice,
  dashboard: dashboardSlice,
  settings: settingsSlice,
  indicators: indicatorSlice,
  notifications: notificationsSlice,
  threatScenario: ThreatScenarioSlice,
  campaign: CampaignSlice,
});
